.push-modal{
  padding-left: 20%;
  .modal-footer{
    position: fixed;
    right: 0;
    bottom: 0;
    background: #fff;
    border-top: solid 2px #f5f5f5;
    width: 80%;
    padding: 7px 20px;

    .btn {
      display: inline-block;
      font-weight: 600;
      position: relative;
      text-transform: none;
      text-align: center;
      vertical-align: middle;
      border: 1px solid transparent;
      padding: 0.625rem 1.25rem;
      transition: all .15s ease;
      letter-spacing: .025em;
      font-size: .875rem;
      will-change: transform;
      line-height: 1.5;
      border-radius: 0.375rem;
    }

    .btn-outline-secondary {
      background-color: transparent;
      background-image: none;
    }

    .btn-outline-secondary:hover {
      color: #212529;
      background-color: #f7fafc;
      border-color: #f7fafc;
    }
  }
  .modal-body{
    padding: 20px 40px;
  }
  .modal-dialog{
    width : 80%;
    max-width: 80%;
    height : 100%;
    margin : 0 auto;
    position: fixed;
    z-index: 0;
    .modal-content{
      height : 100%;
      border-radius : 0;
      -webkit-animation: push-modal-in 0.5s ease-in-out; /* Safari 4.0 - 8.0 */
      animation: push-modal-in 0.5s ease-in-out;
      overflow: auto;
      padding-top: 60px;
      padding-bottom: 50px;

    }
  }
  .modal-header{
    position: fixed;
    width: 80%;
    top : 0;
    background: #fff;
    border-bottom: solid 1px #f1f1f1;
    vertical-align: middle;
    padding: 15px 20px;
    z-index: 2;
    h4{
      line-height: 32px;
    }

    .close:hover, .close:focus {
      background-color: transparent;
      color: rgba(0, 0, 0, 0.9);
      outline: none;
    }

    .close:hover {
      color: rgba(0, 0, 0, 0.6);
      text-decoration: none;
    }
  }
  &.out-modal{
    .modal-dialog{
      .modal-content{
        transition: ease-in-out all 0.5s;
        transform: translate(100%, 0);
      }
    }
  }
}

.modal:not(.push-modal) {
  .modal-dialog {
    .modal-content {
      height: 100%;
      border-radius: 0;
      -webkit-animation: base-modal-in 0.2s linear; /* Safari 4.0 - 8.0 */
      animation: base-modal-in 0.2s linear;

    }
  }
  &.out-modal{
    .modal-dialog{
      .modal-content{
        transition: linear all 0.2s;
        transform: scale(0.8); opacity: 0
      }
    }
  }
}



@keyframes base-modal-in {
  from {transform: scale(0.8); opacity: 0}
  to {transform: scale(1); opacity: 1}
}

@keyframes push-modal-in {
  from {transform: translate(100%, 0);}
  to {transform: translate(0, 0);}
}
