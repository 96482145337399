/* You can add global styles to this file, and also import other style files */

/*@import "../node_modules/@swimlane/ngx-dnd/release/index.css";*/
@import "../node_modules/intl-tel-input/build/css/intlTelInput.css";
@import "assets/_tinymce/lightgray/skin.min.css";
@import "../node_modules/codemirror/lib/codemirror.css";
@import "../node_modules/codemirror/theme/eclipse.css";


.ngx-dnd-container {
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid red;
    margin: 10px;
    padding: 10px; }
.ngx-dnd-container.gu-empty {
    border: 2px dotted red; }
.ngx-dnd-container:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.2); }
.ngx-dnd-container .ex-moved {
    background-color: #e74c3c; }
.ngx-dnd-container .ex-over {
    background-color: rgba(255, 255, 255, 0.9); }
.ngx-dnd-container .handle {
    padding: 0 5px;
    margin-right: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: move; }

.no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE/Edge */
    user-select: none;
    /* non-prefixed version, currently
                                    not supported by any browser */ }

.clearfix::after {
    content: " ";
    display: block;
    height: 0;
    clear: both; }

.ngx-dnd-box,
.ngx-dnd-item {
    margin: 10px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    transition: opacity 0.4s ease-in-out;
    border: none;
}
.ngx-dnd-box:not(.has-handle):not(.move-disabled),
.ngx-dnd-box.has-handle [ngxdraghandle],
.ngx-dnd-box.has-handle [ngxDragHandle],
.ngx-dnd-item:not(.has-handle):not(.move-disabled),
.ngx-dnd-item.has-handle [ngxdraghandle],
.ngx-dnd-item.has-handle [ngxDragHandle] {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab; }
.ngx-dnd-box .ngx-dnd-content,
.ngx-dnd-item .ngx-dnd-content {
    user-select: none; }
.ngx-dnd-box:hover,
.ngx-dnd-item:hover {
    border: none !important; }

.ngx-dnd-box {
    height: 40px;
    width: 40px;
    line-height: 20px;
    text-align: center;
}

.gu-mirror {
    position: absolute !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    display: table-row;
    filter: alpha(opacity=80); }

.gu-hide {
    display: none !important; }

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important; }

.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
    display: table-row;}

.table-order.ngx-dnd-box.ngx-dnd-item {
    margin: 0 !important; ;
    padding: 0 !important;
    background-color: transparent !important;
    transition: none !important;
    border: none !important;
}

.table-order.ngx-dnd-container {
    background-color: transparent;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

.table-order .ngx-dnd-item:hover {
    border: none !important;
}
