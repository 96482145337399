.modal-sm {
	max-width: 400px;
}
.modal-xs {
	max-width: 300px;
}
.modal-xl {
	max-width: 94%;
	margin-left: 3%;
	margin-right: 3%;
}
.icon-align{
	margin-top: 0.6rem;
    margin-left: 0.5rem;
}



.modal:not(.push-modal) {
	@media (min-width: 576px) {
		.modal-content {
			box-shadow: 0 15px 35px rgba(50,50,93,.2), 0 5px 15px rgba(0,0,0,.17);
		}
	}

	.modal-header {
		h4 {
			margin-bottom: .5rem;
			font-family: inherit;
			font-weight: 600;
			line-height: 1.5;
			color: #32325d;
		}

		.close {
			transition: all .15s ease;
			float: right;
			font-size: 1.5rem;
			font-weight: 600;
			line-height: 1;
			color: rgba(0,0,0,.6);
			text-shadow: none;
			opacity: .5;
		}

		.close:hover, .close:focus {
			background-color: transparent;
			color: rgba(0, 0, 0, 0.9);
			outline: none;
		}

		.close:hover {
			color: rgba(0, 0, 0, 0.6);
			text-decoration: none;
		}

	}

	.modal-body {
		p {
			font-size: 1rem;
			font-weight: 300;
			line-height: 1.7;
		}
	}

	.modal-footer {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-end;
		padding: 1.25rem;
		border-top: 0 solid #e9ecef;
		border-bottom-right-radius: .4375rem;
		border-bottom-left-radius: .4375rem;

		.btn {
			display: inline-block;
			font-weight: 600;
			position: relative;
			text-transform: none;
			text-align: center;
			vertical-align: middle;
			border: 1px solid transparent;
			padding: 0.625rem 1.25rem;
			transition: all .15s ease;
			letter-spacing: .025em;
			font-size: .875rem;
			will-change: transform;
			line-height: 1.5;
			border-radius: 0.375rem;
		}

		.btn-outline-secondary {
			background-color: transparent;
			background-image: none;
		}

		.btn-outline-secondary:hover {
			color: #212529;
			background-color: #f7fafc;
			border-color: #f7fafc;
		}
	}

	.modal-footer>* {
		margin: .25rem;
	}
}
