@import "scss/bootstrap.scss";
@import "scss/bootstrap-extended.scss";
@import "scss/colors.scss";
@import "scss/components.scss";
@import "scss/commansocial.scss";
@import "scss/core/menu/navigation";
@import "scss/core/menu/menu-types/vertical-menu-modern.scss";
@import "scss/core/menu/menu-types/horizontal-menu.scss";
@import "scss/core/colors/palette";
@import "scss/core/colors/palette-gradient.scss";
@import "scss/core/colors/palette-callout.scss";
@import "scss/plugins/loaders/loaders.scss";
@import "scss/plugins/animate/animate.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";

.pac-container {
  z-index: 1051 !important;
}
