.notify{
  z-index: 1051;
  .close{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0 !important;
    margin-top: -8px;
    margin-left: -8px;

  }
  .alert{
    -webkit-animation: notifi-move 0.3s linear; /* Safari 4.0 - 8.0 */
    animation: notifi-move 0.3s linear;
       }

  .ng-star-inserted{
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
  }

  i.la{
    font-size: 20px;
    display: block;
    float: left;
    margin-right: 3px;
    color: #ffffff;
  }

}



@keyframes notifi-move {
  from {margin-bottom: 0px;}
  to {margin-bottom: 17px;}
}
