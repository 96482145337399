.collapse-icon{
  .card-header{
    position: relative;
  }
  a[data-toggle="collapse"]{
    &:after{
      position: absolute;
      top: 48%;
      right: 20px;
      margin-top: -8px;
      font-family: 'feather';
      content: "\e982";
      transition: all 300ms linear 0s;
    }
    &:before{
      position: absolute;
      top: 48%;
      right: 20px;
      margin-top: -8px;
      font-family: 'feather';
      content: "\e99d";
      transition: all 300ms linear 0s;
    }
  }
  &.left{
    a{
      padding-left: 28px;
      &:before, &:after{
        left: 20px;
        right: auto;
      }
    }
  }
  a[aria-expanded="true"]{
    &:before{
      opacity: 0;
    }
  }
  a[aria-expanded="false"]{
    &:after{
      opacity: 0;
    }
  }
  &.accordion-icon-rotate{
    a[aria-expanded="true"]{
      &:before{
        transform: rotate(180deg);
      }
    }
    a[aria-expanded="false"]{
      &:after{
        transform: rotate(-180deg);
      }
    }
  }
}

// Mega Menu Accordion Section

.header-navbar{
  .navbar-container{
    .mega-dropdown-menu{
      .collapse-icon{
        a[data-toggle="collapse"]{
          &:after{
            margin-top: -16px;
          }
          &:before{
            margin-top: -16px;
          }
        }
      }
    }
  }
}
