// ================================================================================================
// 	File Name: components.scss
// 	Description: Common components file to includ all theme specific custom components.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Modern Admin - Clean Bootstrap 4 Dashboard HTML Template
// 	 Version: 1.0
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Variables
// ------------------------------

// Core variables and mixins
@import "bootstrap/functions";
@import "bootstrap/mixins";
@import "bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "core/variables/bootstrap-variables";
@import "bootstrap/variables";

@import "core/variables/components-variables";
@import "core/mixins/transitions";

// Perfect Scrollbar

// Core
// ------------------------------
// layouts
@import "core/layouts/content";
@import "core/layouts/sidebar";
@import "core/layouts/horizontal";
@import "core/layouts/footer";
// menu
@import "core/menu/navigation";

// Components
// ------------------------------
@import "components/helper";
@import "components/avatar";
@import "components/search";
@import "components/bootstrap-social";
@import "components/content-header-image";
@import "components/customizer"; // Customizer demo, remove in real project
@import "components/footer"; // Scroll to Top, remove in real project

// Plugins
// ------------------------------
@import "plugins/menu/perfect-scrollbar/ps-custom";
@import "plugins/ui/breakpoints";
@import "plugins/forms/tags/basic-input";

.table-order {
  .ngx-dnd-box, .ngx-dnd-item {
    margin: 0 !important;
    padding: 0 !important;
    background-color: transparent !important;
    transition: none !important;
    border: none !important;
    display: table-row !important;
  }
  .ngx-dnd-container {
    background-color: transparent !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

