@import '../variables';
@import '../mixins';
@import '../functions';

$size: 60px;

@keyframes ball-scale-multiple {
  0% {
    transform: scale(0.0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    transform: scale(1.0);
    opacity: 0;
  }
}

@mixin ball-scale-multiple ($n: 3, $start: 2) {
  @for $i from $start through $n {
    > div:nth-child(#{$i}) {
      animation-delay: delay(0.2s, $n, $i - 1);
    }
  }
}

.ball-scale-multiple {
  @include ball-scale-multiple();

  position: relative;
  // transform: translateY(-$size / 2);

  > div {
    @include balls();
    @include global-animation();

    position: absolute;
    // left: -30px;
    top: 0px;
    opacity: 0;
    margin: 0;
    width: $size;
    height: $size;
    animation: ball-scale-multiple 1s 0s linear infinite;
  }
}
