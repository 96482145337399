/*!
 * Bootstrap v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

 @import "bootstrap/functions";

 @import "core/variables/bootstrap-variables";
 @import "bootstrap/variables";

 @import "bootstrap/bootstrap";
