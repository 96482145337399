@use 'sass:math';
@import '../variables';
@import '../mixins';
@import '../functions';

$radius: 25px;

@keyframes ball-spin-fade-loader {
  50% {
    opacity: 0.3;
    transform: scale(0.4);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@mixin ball-spin-fade-loader($n:8, $start:1) {
  @for $i from $start through $n {
    > div:nth-child(#{$i}) {
      $iter: math.div(360, $n);
      $quarter: (math.div($radius, 2)) + (math.div($radius, 5.5));

      @if $i == 1 {
        top: $radius;
        left: 0;
      } @else if $i == 2 {
        top: $quarter;
        left: $quarter;
      } @else if $i == 3 {
        top: 0;
        left: $radius;
      } @else if $i == 4 {
        top: -$quarter;
        left: $quarter;
      } @else if $i == 5 {
        top: -$radius;
        left: 0;
      } @else if $i == 6 {
        top: -$quarter;
        left: -$quarter;
      } @else if $i == 7 {
        top: 0;
        left: -$radius;
      } @else if $i == 8 {
        top: $quarter;
        left: -$quarter;
      }

      animation: ball-spin-fade-loader 1s delay(0.12s, $n, $i - 1) infinite linear;
    }
  }
}

.ball-spin-fade-loader {
  @include ball-spin-fade-loader();

  position: relative;
  top: -10px;
  left: -10px;

  > div {
    @include balls();
    @include global-animation();

    position: absolute;
  }
}
