
// cropper load

.loading-crop {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: white;
  text-align: center;
  top: 0;
  left: 0;
  img{
    width: auto;
    height: 40px;
  }
  small{
    width: 100%;
    display: inline-block;
    color : #696969;
    font-size: 12px;
    padding: 25px 0;
  }
  p{
    margin-bottom: 0;
    position: absolute;
    margin-top: 0;
    top: 12%;
    width: 100%;
  }
  .loading-cont{
    margin-bottom: 0;
    position: absolute;
    top: 18%;
    width: 100%;
  }
}

.add-image {
  i {
    font-size: 26px;
    color: black;
    position: absolute;
    left: 24px;
    top: 17px;
  }
  img {
    border: gray solid 1px;
    width: 60px;
    height: 60px;
    opacity: 0.5;
  }
}

.color-select {
  .item-img {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 1px;
      border: solid 10px transparent;
      border-bottom-color: #8c8c8c;
      left: 50%;
      margin-left: -10px;
      top: -26px;
      z-index: 2;
    }
  }
}

.banner-crop {
  .y-root-i6, .i6, .y-cropping-b {
    width: auto !important;
    height: 500px !important;
  }
}

.max-crop {
  .y-root-i6, .i6, .y-cropping-b {
    width: auto !important;
    height: 1000px !important;
  }
}

.vertical-crop {
  .y-root-i6, .i6, .y-cropping-b {
    width: auto !important;
    height: 700px !important;
  }
}

.default-crop {
  .y-root-i6, .i6, .y-cropping-b {
    width: auto;
    height: 400px !important;
  }
}

.avatar-crop {
  .y-root-i6, .i6, .y-cropping-b {
    width: auto;
    height: 350px !important;
  }
}

.img-top{
  width: auto;
  height: 400px;
  margin-bottom: 10px;
}

.img-c{

  .mat-menu-item {
    small {
      font-family: "Poppins", sans-serif !important;
      font-size: 10px;
      font-weight: 400;
    }
  }
}

.email-verify {
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: 0 !important;
  }
}

.crop-shadow {

  .i8, .y-cropping-container-i8, .y-cropping-container-i7  {
    &:after {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    }
  }

  .i7, .y-area-i7 {
    &:after {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    }
  }

  .y-root-i6, .i6, .y-cropping-b {
    background-image: url('/assets/images/cuadricula.jpg');
  }
}
