@use 'sass:math';
@import '../variables';
@import '../mixins';

$amount: 30px;

@keyframes ball-zig {
  33% {
    transform: translate(math.div(-$amount, 2), -$amount);
  }
  66% {
    transform: translate(math.div($amount, 2), -$amount);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes ball-zag {
  33% {
    transform: translate(math.div($amount, 2), $amount);
  }
  66% {
    transform: translate(math.div(-$amount, 2), $amount);
  }
  100% {
    transform: translate(0, 0);
  }
}

.ball-zig-zag {
  position: relative;
  transform: translate(math.div(-$amount, 2), math.div(-$amount, 2));

  > div {
    @include balls();
    @include global-animation();

    position: absolute;
    margin-left: math.div($amount, 2);
    top: 4px;
    left: -7px;

    &:first-child {
      animation: ball-zig 0.7s 0s infinite linear;
    }

    &:last-child {
      animation: ball-zag 0.7s 0s infinite linear;
    }
  }
}
