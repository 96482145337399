
.divider-section{
  color: $menu-dark-bg;
  line-height: 3rem;
  margin-bottom: 20px;
  border-bottom: 1px solid $menu-dark-bg;

  i{
    font-size: 20px;
    margin-left: 5px;
    margin-right: 10px;
  }
}
