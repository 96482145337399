.card-group,
.card-deck {
	margin-bottom: 0.75rem;
}

.card {
	// box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05);
	margin-bottom: 1.875rem;
	border: none;
	// border-radius: 0.42rem;
	box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07);

	.card {
		box-shadow: none !important;
	}

	.card-title {
		font-weight: 500;
		letter-spacing: 0.05rem;
		font-size: 1.12rem;
	}

	.card-bordered {
		border: $card-border-width solid $card-border-color;
	}

	//fullscreen card
	&.card-fullscreen {
		display: block;
		z-index: 9999;
		position: fixed;
		width: 100% !important;
		height: 100% !important;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		overflow: auto;
	}

	.card-body[class*='border-bottom-'] {
		border-bottom-width: 2px !important;
	}

	.card-img-overlay {
		&.bg-overlay {
			background: rgba(0, 0, 0, 0.45);
		}

		.text-muted {
			color: $gray-800 !important;
		}
	}

	&.card-minimal {
		border: none;
		box-shadow: none;
	}

	&.crypto-card-3 {
		.card-body:before {
			font-family: "cryptocoins";
			font-size: 10rem;
			color: rgba($white, 0.15);
			position: absolute;
			bottom: -32px;
			right: 0px;
		}
	}

}

// If card moved
.card-moved {

	.card,
	.card-header {
		background-color: $warning;
		color: $white;
	}
}

.handle,
.titleArea {
	cursor: move;
}

.card-header {
	border-bottom: none;

	.card-title {
		margin-bottom: 0;
	}

	.heading-elements,
	.heading-elements-toggle {
		background-color: inherit;
		position: absolute;
		top: 20px;
		right: 20px;

		&.heading-top-elements {

			// top: 0px;
			.page-item {
				display: inline-block
			}
		}

		a {
			padding: 0 8px;

			&.btn {
				padding-top: 6px;
				padding-bottom: 6px;
			}
		}
	}
}

.card-footer {
	border-top: 1px solid #EEEEEE;
}

.text-white {
	.card-text {
		color: rgba(255, 255, 255, 1);
	}

	.card-img-overlay {
		.text-muted {
			color: #FFF !important;
		}
	}

	code {
		background-color: rgba(255, 255, 255, 1);
	}

	.heading-elements {
		i {
			color: #FFF;
		}
	}
}

.card-head-inverse {
	.heading-elements {
		i {
			color: #FFF;
		}
	}

	color: #FFF;
}

.card-transparent {
	background-color: transparent;
}

// Card Ecommerce
.card-image-position-right {
	margin-left: 50px;
	position: absolute;
	top: -27px;
}

.card-image-position-right-mid {
	position: absolute;
	top: auto;
	right: 0px;
	width: 250px;
}

.card-font-style-black {
	font-size: 90px;
	font-weight: 900;
	color: #ffffff52;
	margin-top: -12px;
}

.card-font-style-white {
	font-size: 72px;
	font-weight: 900;
	margin-top: -10px;
}

.background-round {
	background-color: rgba(0, 0, 0, 0.18);
	padding: 8px;
	border-radius: 50%;
}

@include media-breakpoint-down(md) {
	.heading-elements {
		// text-align: center;

		.list-inline {
			display: none;
		}

		&.visible {
			.list-inline {
				display: block;
			}

			display: block;
			margin-top: 0;
			top: 100%;
			top: 37px;
			height: auto;
			left: 0px;
			padding: 10px;
			text-align: right;
			// border: 1px solid #eee;
			z-index: 997;
			position: absolute;
			width: 100%;
		}
	}
}

@include media-breakpoint-up(lg) {
	.heading-elements-toggle {
		display: none;
	}
}

//
// Profile card
//

.card-profile-image {
	position: relative;

	img {
		@extend .shadow;
		transition: $transition-base;

		max-width: 140px;
		border-radius: 0.375rem;
		position: absolute;
		/* transition: all 0.15s ease; */
		/* float: left; */
		left: -8px;
		top: -30px;

		&:hover {
			transform: translate(0, -1%);
		}

		float: left;
	}
}

.avatar-profile figure .overlay {
	opacity: 0;
	visibility: hidden;
	z-index: 10;
	top: -30px;
	left: -8px;
	right: 0;
	bottom: 0;
	position: absolute;
	background: rgba(0, 0, 0, 0.4);
	-webkit-transition: 0.7s;
	transition: 0.7s;
	width: 140px;
	height: 140px !important;
	border-radius: 50%;

	i {
		z-index: 12;
		color: #fff;
		font-size: 30px;
		position: absolute;
		margin-left: -15px;
		margin-top: -45px;
		top: 50%;
		left: 50%;
		-webkit-transition: 0.3s;
		-o-transition: 0.3s;
		transition: 0.3s;
	}
}
.avatar-profile img {
	z-index: 8;
	opacity: 1;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}

.avatar-profile:hover figure .overlay, .avatar-profile:focus figure .overlay {
	opacity: 1;
	visibility: visible;
}
.avatar-profile:hover figure .overlay i, .avatar-profile:focus figure .overlay i {
	margin-top: -15px;
}

.card-profile-stats {
	padding: 1rem 0;

	> div {
		text-align: center;
		margin-right: 1rem;
		padding: .875rem;

		&:last-child {
			margin-right: 0;
		}

		.heading {
			font-size: 1.1rem;
			font-weight: bold;
			display: block;
		}
		.description {
			font-size: .875rem;
			color: $gray-500;
		}
	}
}

.card-profile-actions {
	padding: .875rem;
}

.curriculum-avatar {
	height: auto;
	width: 240px;
}
.avatar-content-generator {
	height: auto;
	width: 200px;
}
.avatar-content {
	height: auto;
	width: 150px;
}
.card {
	&.card-login {
		position: relative;
		display: flex;
		flex-direction: column;
		min-width: 0;
		word-wrap: break-word;
		background-color: #ffffffcc !important;
		background-clip: border-box;
		border: 1px solid rgba(0, 0, 0, 0.06);
		border-radius: 0.35rem;

		.form-control {
			display: block;
			width: 100%;
			height: calc(1.25em + 1.5rem + 2px);
			padding: 0.75rem 1rem;
			font-size: 1rem;
			font-weight: 400;
			line-height: 1.25;
			color: #4e5154;
			background-color: rgb(255 255 255 / 70%) !important;
			background-clip: padding-box;
			border: 1px solid #babfc7;
			border-radius: 0.25rem;
			transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		}
		.card-header {
			padding: 1.5rem 1.5rem;
			margin-bottom: 0;
			background-color: #ffffff00 !important;
			border-bottom: 1px solid rgba(0, 0, 0, 0.06);
		}
	}
}

.img-login{
	width: 200px;
	height: 130px;
}

.accordion-card {
	.card-header {
		padding: 0 !important;
		margin-bottom: 0 !important;
		border-top: none !important;
		&:first-child {
			border-radius: 0 !important;
		}
	}
	model {

		//.card {
		//	box-shadow: 0 1px 15px 1px rgb(62 57 107 / 7%) !important;
		//}
		.card-header {
			padding: $card-spacer-y $card-spacer-x !important;
			margin-bottom: 0; // Removes the default margin-bottom of <hN>
			color: $card-cap-color;
			background-color: $card-cap-bg;
			border-bottom: $card-border-width solid $card-border-color;
			background: initial !important;
			border-bottom: initial !important;
			border-top: none !important;
			&:first-child {
				@include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
			}
			+ .list-group {
				.list-group-item:first-child {
					border-top: 0;
				}
			}
		}

	}
	.card {
		margin-bottom: 0 !important;
	}

	.icon-accordion {
		position: relative;
		top: 14px;
		font-size: 24px;
	}

	.media-character {
		font-size: 24px;
	}
	.td-border-accordion {
		border-top: none !important;
	}

	ng-actions {
		td {
			border-top: none !important;
			width: 200px;
		}
	}
}
.th-width {
	border-top: none !important;
	width: 150px;
	.text-hidden {
		width: 150px !important;
		overflow:hidden !important;
		white-space: nowrap !important;
		text-overflow: ellipsis !important;
		word-wrap: break-word !important;
	}
}

.td-width{
	border-top: none !important;
	width: 200px;
}

.table-responsive.ps {
	overflow: visible !important;
}

.btn-actions {
	button {
		i {
			font-size: 20px !important;
		}
	}
}
